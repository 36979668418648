import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";
import { FaRegCopy } from "react-icons/fa";

const WORKFLOW_STATES = ["active", "paused", "archived"];

function AdminPage({ googleAuthToken }) {
  const [name, setName] = useState("");
  const [apiKeyTag, setApiKeyTag] = useState("");
  const [addtionalApiKeyTag, setAddtionalApiKeyTag] = useState("");
  const [newCustomerResult, setNewCustomerResult] = useState("");
  const [additionalKeyResult, setAdditionalKeyResult] = useState("");
  const [customerIdInput, setCustomerIdInput] = useState("");
  const [customers, setCustomers] = useState([]);
  const [apiKeyToValidate, setApiKeyToValidate] = useState("");
  const [validationResult, setValidationResult] = useState("");
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [updateDescriptionResult, setUpdateDescriptionResult] = useState("");
  const [workflowCustomerId, setWorkflowCustomerId] = useState("");
  const [workflowDisplayName, setWorkflowDisplayName] = useState("");
  const [hasDescriptionChanged, setHasDescriptionChanged] = useState(false);
  const [hasDisplayNameChanged, setHasDisplayNameChanged] = useState(false);
  const [updateResult, setUpdateResult] = useState("");
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [workflowState, setWorkflowState] = useState("");
  const [hasStateChanged, setHasStateChanged] = useState(false);
  const [metricName, setMetricName] = useState("");
  const [metricDefinition, setMetricDefinition] = useState("");
  const [isOsMetric, setIsOsMetric] = useState(false);
  const [osMetricType, setOsMetricType] = useState("");
  const [metricType, setMetricType] = useState("");
  const [metricAggregation, setMetricAggregation] = useState("");
  const [timeInterval, setTimeInterval] = useState("");
  const [metricFormat, setMetricFormat] = useState("");
  const [metricResult, setMetricResult] = useState("");
  const [metricsCustomerId, setMetricsCustomerId] = useState("");
  const [existingMetrics, setExistingMetrics] = useState([]);
  const [selectedMetricWorkflow, setSelectedMetricWorkflow] = useState("");
  const [metricIdToDelete, setMetricIdToDelete] = useState("");
  const [deleteMetricResult, setDeleteMetricResult] = useState("");
  const [observables, setObservables] = useState([]);
  const [selectedObservable, setSelectedObservable] = useState("");
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState("");
  const [editingMetric, setEditingMetric] = useState(null);
  const [editMetricResult, setEditMetricResult] = useState("");

  const { env, switchEnvironment } = useContext(EnvironmentContext);

  // Add constants for dropdown options
  const OS_METRIC_TYPES = ["instance", "observable", "action"];
  const METRIC_TYPES = [
    "line_chart",
    "bar_chart",
    "stacked_bar_chart",
    "table",
  ];
  const METRIC_AGGREGATIONS = ["sum", "count", "average", "min", "max"];
  const TIME_INTERVALS = ["hour", "day", "week", "month", "year"];

  // Add constant for metric format options
  const METRIC_FORMATS = {
    0: "(e.g., 1235)",
    "0.0": "(e.g., 1234.5)",
    "0.00": "(e.g., 1234.56)",
    "#,##0": "(e.g., 1,235)",
    "#,##0.00": "(e.g., 1,234.56)",
  };

  useEffect(() => {
    if (env !== "dev") {
      switchEnvironment("dev");
    }
  }, []);

  useEffect(() => {
    // Reset selections when environment changes
    setWorkflowCustomerId("");
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setUpdateDescriptionResult("");
    setExistingMetrics([]);
    setSelectedMetricWorkflow("");
    setMetricsCustomerId("");
    setActions([]);
    setIsOsMetric(false);
    setOsMetricType("");
    setSelectedObservable("");
    setSelectedAction("");
  }, [env]);

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }, [googleAuthToken]);

  useEffect(() => {
    fetchCustomers();
  }, [env, fetchCustomers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer`,
        {
          name,
          apiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setNewCustomerResult(
        `Customer added successfully. API Key: ${response.data.apiKey}`
      );
    } catch (error) {
      setNewCustomerResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  async function addCustomerKey(customerId) {
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer key to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer-key`,
        {
          customerId,
          tag: addtionalApiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setAdditionalKeyResult(`New API Key added: ${response.data.apiKey}`);
    } catch (error) {
      setAdditionalKeyResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  }

  const validateApiKey = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/validate-api-key`,
        {
          apiKey: apiKeyToValidate,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setValidationResult(
        `Valid API key for customer: ${response.data.customerName} (Tag: ${response.data.tag})`
      );
    } catch (error) {
      setValidationResult(`Invalid API key`);
    }
  };

  const fetchWorkflows = async (customerId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/customer-workflows/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      const workflowObjects = response.data.map((workflow) => ({
        id: workflow.id,
        name: workflow.name,
        description: workflow.description,
        display_name: workflow.display_name,
        state: workflow.state,
      }));
      setWorkflows(workflowObjects);
      return workflowObjects;
    } catch (error) {
      console.error("Error fetching workflows:", error);
      return [];
    }
  };

  const handleWorkflowCustomerChange = (e) => {
    const customerId = e.target.value;
    setWorkflowCustomerId(customerId);
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setWorkflowDisplayName("");
    setUpdateDescriptionResult("");
    setVersions([]);
    setSelectedVersion("");

    if (workflowCustomerId) {
      fetchWorkflows(workflowCustomerId);
    }
  };

  useEffect(() => {
    if (workflowCustomerId) {
      fetchWorkflows(workflowCustomerId);
    }
  }, [workflowCustomerId]);

  const handleWorkflowChange = (e) => {
    const workflowName = e.target.value;
    setSelectedWorkflow(workflowName);
    const workflow = workflows.find((w) => w.name === workflowName);
    setWorkflowDescription(workflow?.description || "");
    setWorkflowDisplayName(workflow?.display_name || "");
    setWorkflowState(workflow?.state || "");
    setHasDescriptionChanged(false);
    setHasDisplayNameChanged(false);
    setHasStateChanged(false);
    setUpdateResult("");
    setSelectedVersion("");

    if (workflow) {
      fetchWorkflowVersions(workflow.id);
      fetchWorkflowMetrics(workflow.id);
    } else {
      setVersions([]);
      setExistingMetrics([]);
    }
  };

  const handleDescriptionChange = (e) => {
    setWorkflowDescription(e.target.value);
    setHasDescriptionChanged(true);
  };

  const handleDisplayNameChange = (e) => {
    setWorkflowDisplayName(e.target.value);
    setHasDisplayNameChanged(true);
  };

  const handleStateChange = (e) => {
    setWorkflowState(e.target.value);
    setHasStateChanged(true);
  };

  const updateWorkflowDetails = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to update this workflow in the production environment?"
      )
    ) {
      return;
    }

    try {
      const workflow = workflows.find((w) => w.name === selectedWorkflow);
      if (!workflow) return;

      const updates = [];

      if (hasDescriptionChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-description/${workflow.id}`,
            { description: workflowDescription },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (hasDisplayNameChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-display-name/${workflow.id}`,
            { display_name: workflowDisplayName },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (hasStateChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-state/${workflow.id}`,
            { state: workflowState },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (updates.length > 0) {
        await Promise.all(updates);
        setUpdateResult("Workflow details updated successfully");

        // Refresh workflows
        if (workflowCustomerId) {
          const updatedWorkflows = await fetchWorkflows(workflowCustomerId);
          const updatedWorkflow = updatedWorkflows.find(
            (w) => w.name === selectedWorkflow
          );
          setWorkflowDescription(updatedWorkflow?.description || "");
          setWorkflowDisplayName(updatedWorkflow?.display_name || "");
          setWorkflowState(updatedWorkflow?.state || "");

          if (updatedWorkflow) {
            await fetchWorkflowVersions(updatedWorkflow.id);
          }
        }

        setHasDescriptionChanged(false);
        setHasDisplayNameChanged(false);
        setHasStateChanged(false);
      }
    } catch (error) {
      setUpdateResult(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  const fetchWorkflowVersions = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflow-versions/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setVersions(response.data);
    } catch (error) {
      console.error("Error fetching workflow versions:", error);
    }
  };

  const handleVersionChange = async (e) => {
    const versionId = e.target.value;
    setSelectedVersion(versionId);
  };

  const handleMetricsWorkflowChange = (e) => {
    const workflowName = e.target.value;
    setSelectedMetricWorkflow(workflowName);
    setSelectedObservable("");
    setSelectedAction("");
    const workflow = workflows.find((w) => w.name === workflowName);

    if (workflow) {
      fetchWorkflowMetrics(workflow.id);
      fetchObservables(workflow.id);
      fetchActions(workflow.id);
    } else {
      setExistingMetrics([]);
      setObservables([]);
      setActions([]);
    }
  };

  const handleMetricSubmit = async (e) => {
    e.preventDefault();
    if (!selectedMetricWorkflow) return;

    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add this metric in the production environment?"
      )
    ) {
      return;
    }

    const workflow = workflows.find((w) => w.name === selectedMetricWorkflow);
    if (!workflow) return;

    // Format the OS metric field based on type
    let formattedOsMetric = null;
    if (isOsMetric) {
      switch (osMetricType) {
        case "action":
          formattedOsMetric = `a_${selectedAction}`;
          break;
        case "observable":
          formattedOsMetric = `o_${selectedObservable}`;
          break;
        case "instance":
          formattedOsMetric = "num_instances";
          break;
        default:
          formattedOsMetric = null;
          break;
      }
    }

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/metrics`,
        {
          workflow_id: workflow.id,
          metric_name: metricName,
          metric_definition: metricDefinition,
          os_metric: formattedOsMetric,
          metric_type: metricType,
          metric_aggregation: metricAggregation,
          time_interval: timeInterval,
          metric_format: metricFormat,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setMetricResult(
        `Metric added successfully. Metric ID: ${response.data.id}`
      );

      // Refresh the metrics list
      await fetchWorkflowMetrics(workflow.id);

      // Reset form
      setMetricName("");
      setMetricDefinition("");
      setIsOsMetric(false);
      setOsMetricType("");
      setMetricType("");
      setMetricAggregation("");
      setTimeInterval("");
      setMetricFormat("");
    } catch (error) {
      setMetricResult(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleMetricsCustomerChange = (e) => {
    const customerId = e.target.value;
    setMetricsCustomerId(customerId);
    setSelectedMetricWorkflow("");
    setWorkflows([]);
    setExistingMetrics([]);

    if (customerId) {
      fetchWorkflows(customerId);
    }
  };

  const fetchWorkflowMetrics = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/metrics/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setExistingMetrics(response.data);
    } catch (error) {
      console.error("Error fetching workflow metrics:", error);
    }
  };

  const fetchObservables = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflow-observables/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setObservables(response.data);
    } catch (error) {
      console.error("Error fetching observables:", error);
      setObservables([]);
    }
  };

  const fetchActions = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflow-actions/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setActions(response.data);
    } catch (error) {
      console.error("Error fetching actions:", error);
      setActions([]);
    }
  };

  const copyToClipboard = async (text, e) => {
    if (e) e.preventDefault();
    try {
      await navigator.clipboard.writeText(text);
      // Optional: You could add a toast/notification here
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const formatId = (id) => {
    return `...${id.slice(-4)}`;
  };

  const handleDeleteMetric = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to delete this metric in the production environment?"
      )
    ) {
      return;
    }

    try {
      await axios.delete(`${config.apiBaseUrl}/metrics/${metricIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setDeleteMetricResult("Metric deleted successfully");
      setMetricIdToDelete("");

      // Refresh metrics list if a workflow is selected
      if (selectedMetricWorkflow) {
        const workflow = workflows.find(
          (w) => w.name === selectedMetricWorkflow
        );
        if (workflow) {
          await fetchWorkflowMetrics(workflow.id);
        }
      }
    } catch (error) {
      setDeleteMetricResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  const handleOsMetricChange = (e) => {
    setIsOsMetric(e.target.checked);
    if (!e.target.checked) {
      // Reset related fields when OS metric is unchecked
      setOsMetricType("");
      setSelectedObservable("");
      setSelectedAction("");
    }
  };

  const handleEditMetric = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to edit this metric in the production environment?"
      )
    ) {
      return;
    }

    try {
      await axios.put(
        `${config.apiBaseUrl}/metrics/${editingMetric.id}`,
        {
          metric_name: editingMetric.metric_name,
          metric_definition: editingMetric.metric_definition,
          metric_type: editingMetric.metric_type,
          metric_aggregation: editingMetric.metric_aggregation,
          time_interval: editingMetric.time_interval,
          metric_format: editingMetric.metric_format,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );

      setEditingMetric(null);

      // Refresh the metrics list while maintaining the current selections
      const workflow = workflows.find((w) => w.name === selectedMetricWorkflow);
      if (workflow) {
        await fetchWorkflowMetrics(workflow.id);
      }
    } catch (error) {
      setEditMetricResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  return (
    <div className="AdminPage">
      <h1>Admin Tools</h1>
      <EnvSwitcher onEnvironmentSwitch={switchEnvironment} currentEnv={env} />
      <div className="admin-columns">
        <div className="admin-column">
          <div className="container">
            <h2>Add New Customer</h2>
            <form onSubmit={handleSubmit}>
              <label>
                <input
                  type="text"
                  placeholder="Customer Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <label>
                <input
                  type="text"
                  placeholder="API Key Tag ('default' if left blank)"
                  value={apiKeyTag}
                  onChange={(e) => setApiKeyTag(e.target.value)}
                />
              </label>
              <button type="submit">Add Customer</button>
            </form>
            {newCustomerResult && (
              <div className="result">{newCustomerResult}</div>
            )}
          </div>
          <div className="container">
            <h2>Add Additional Key</h2>
            <div>
              <label>
                <select
                  value={customerIdInput}
                  onChange={(e) => setCustomerIdInput(e.target.value)}
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                <input
                  type="text"
                  placeholder="API Key Tag"
                  value={addtionalApiKeyTag}
                  onChange={(e) => setAddtionalApiKeyTag(e.target.value)}
                />
              </label>
              <br />
              <button
                type="submit"
                onClick={() =>
                  addCustomerKey(customerIdInput, addtionalApiKeyTag)
                }
                disabled={!customerIdInput || !addtionalApiKeyTag}
              >
                Add Customer Key
              </button>
            </div>
            {additionalKeyResult && (
              <div className="result">{additionalKeyResult}</div>
            )}
          </div>
          <div className="container">
            <h2>Validate API Key</h2>
            <form onSubmit={validateApiKey}>
              <label>
                <input
                  type="text"
                  placeholder="Enter API Key to validate"
                  value={apiKeyToValidate}
                  onChange={(e) => setApiKeyToValidate(e.target.value)}
                  required
                />
              </label>
              <button type="submit">Validate Key</button>
            </form>
            {validationResult && (
              <div className="result">{validationResult}</div>
            )}
          </div>
          <div className="container">
            <h2>Edit Workflow Details</h2>
            <div>
              <label>
                <select
                  value={workflowCustomerId}
                  onChange={handleWorkflowCustomerChange}
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                <select
                  value={selectedWorkflow}
                  onChange={handleWorkflowChange}
                  disabled={!workflowCustomerId}
                >
                  <option value="">Select Workflow</option>
                  {workflows.map((workflow) => (
                    <option key={workflow.id} value={workflow.name}>
                      {workflow.name} ({workflow.state || "unknown"})
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <form onSubmit={updateWorkflowDetails}>
                <textarea
                  placeholder="Workflow Description"
                  value={workflowDescription}
                  onChange={handleDescriptionChange}
                  disabled={!selectedWorkflow}
                  rows={4}
                  style={{ resize: "vertical" }}
                />
                <br />
                <textarea
                  placeholder="Workflow Display Name"
                  value={workflowDisplayName}
                  onChange={handleDisplayNameChange}
                  disabled={!selectedWorkflow}
                  rows={4}
                  style={{ resize: "vertical" }}
                />
                <br />
                <select
                  value={workflowState}
                  onChange={handleStateChange}
                  disabled={!selectedWorkflow}
                >
                  <option value="">Select State</option>
                  {WORKFLOW_STATES.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <div style={{ marginTop: "20px" }}>
                  <select
                    value={selectedVersion}
                    onChange={handleVersionChange}
                    disabled={!selectedWorkflow}
                  >
                    <option value="">Select Version</option>
                    {versions.map((version) => (
                      <option key={version.id} value={version.id}>
                        Version {version.version}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  type="submit"
                  disabled={
                    !selectedWorkflow ||
                    (!hasDescriptionChanged &&
                      !hasDisplayNameChanged &&
                      !hasStateChanged)
                  }
                >
                  Update Workflow Details
                </button>
              </form>
              {updateResult && <div className="result">{updateResult}</div>}
            </div>
          </div>
        </div>
        <div className="admin-column">
          <div className="container">
            <h2>Add Workflow Metric</h2>
            <div>
              {existingMetrics.length > 0 && (
                <div
                  className="existing-metrics"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <h3>Existing Metrics</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Type</th>
                        <th>OS Metric</th>
                        <th>Aggregation</th>
                        <th>Time Interval</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingMetrics.map((metric) => (
                        <tr key={metric.id}>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              {formatId(metric.id)}
                              <button
                                onClick={(e) => copyToClipboard(metric.id, e)}
                                style={{
                                  border: "none",
                                  background: "none",
                                  cursor: "pointer",
                                  padding: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                title="Copy full ID"
                                type="button"
                              >
                                <FaRegCopy size={14} />
                              </button>
                            </div>
                          </td>
                          <td>{metric.metric_name}</td>
                          <td>{metric.metric_definition || "-"}</td>
                          <td>{metric.metric_type}</td>
                          <td>{metric.os_metric || "-"}</td>
                          <td>{metric.metric_aggregation}</td>
                          <td>{metric.time_interval}</td>
                          <td>
                            <button
                              onClick={() => setEditingMetric(metric)}
                              style={{ marginRight: "8px" }}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {editingMetric && (
                <div
                  className="edit-metric-form"
                  style={{ marginBottom: "20px" }}
                >
                  <h3>Edit Metric</h3>
                  <form onSubmit={handleEditMetric}>
                    <input
                      type="text"
                      placeholder="Metric Name"
                      value={editingMetric.metric_name}
                      onChange={(e) =>
                        setEditingMetric({
                          ...editingMetric,
                          metric_name: e.target.value,
                        })
                      }
                      required
                    />
                    <br />
                    <textarea
                      placeholder="Metric Definition"
                      value={editingMetric.metric_definition}
                      onChange={(e) =>
                        setEditingMetric({
                          ...editingMetric,
                          metric_definition: e.target.value,
                        })
                      }
                      required
                      rows={4}
                      style={{ resize: "vertical" }}
                    />
                    <br />
                    <select
                      value={editingMetric.metric_type}
                      onChange={(e) =>
                        setEditingMetric({
                          ...editingMetric,
                          metric_type: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Display Type</option>
                      {METRIC_TYPES.map((type) => (
                        <option key={type} value={type}>
                          {type.replace("_", " ")}
                        </option>
                      ))}
                    </select>
                    <br />
                    <select
                      value={editingMetric.metric_aggregation}
                      onChange={(e) =>
                        setEditingMetric({
                          ...editingMetric,
                          metric_aggregation: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Aggregation Method</option>
                      {METRIC_AGGREGATIONS.map((agg) => (
                        <option key={agg} value={agg}>
                          {agg}
                        </option>
                      ))}
                    </select>
                    <br />
                    <select
                      value={editingMetric.time_interval}
                      onChange={(e) =>
                        setEditingMetric({
                          ...editingMetric,
                          time_interval: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Time Interval</option>
                      {TIME_INTERVALS.map((interval) => (
                        <option key={interval} value={interval}>
                          {interval}
                        </option>
                      ))}
                    </select>
                    <br />
                    <select
                      value={editingMetric.metric_format}
                      onChange={(e) =>
                        setEditingMetric({
                          ...editingMetric,
                          metric_format: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Format</option>
                      {Object.entries(METRIC_FORMATS).map(
                        ([format, description]) => (
                          <option key={format} value={format}>
                            {format} {description}
                          </option>
                        )
                      )}
                    </select>
                    <br />
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <button type="submit">Save Changes</button>
                      <button
                        type="button"
                        onClick={() => {
                          setEditingMetric(null);
                          setEditMetricResult("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                  {editMetricResult && (
                    <div className="result">{editMetricResult}</div>
                  )}
                </div>
              )}
            </div>
            <form onSubmit={handleMetricSubmit}>
              <label>
                <select
                  value={metricsCustomerId}
                  onChange={handleMetricsCustomerChange}
                  required
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                <select
                  value={selectedMetricWorkflow}
                  onChange={handleMetricsWorkflowChange}
                  disabled={!metricsCustomerId}
                  required
                >
                  <option value="">Select Workflow</option>
                  {workflows.map((workflow) => (
                    <option key={workflow.id} value={workflow.name}>
                      {workflow.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <input
                type="text"
                placeholder="Metric Name"
                value={metricName}
                onChange={(e) => setMetricName(e.target.value)}
                required
                disabled={!selectedMetricWorkflow}
              />
              <br />
              <textarea
                placeholder="Metric Definition"
                value={metricDefinition}
                onChange={(e) => setMetricDefinition(e.target.value)}
                required
                rows={4}
                style={{ resize: "vertical" }}
                disabled={!selectedMetricWorkflow}
              />
              <br />
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={isOsMetric}
                  onChange={handleOsMetricChange}
                  disabled={!selectedMetricWorkflow}
                />
                OS Metric
              </label>
              <br />
              {isOsMetric && (
                <select
                  value={osMetricType}
                  onChange={(e) => setOsMetricType(e.target.value)}
                  required
                  disabled={!selectedMetricWorkflow}
                >
                  <option value="">OS Metric Type</option>
                  {OS_METRIC_TYPES.map((type) => (
                    <option key={type} value={type}>
                      {type.replace("_", " ")}
                    </option>
                  ))}
                </select>
              )}
              {isOsMetric && osMetricType === "observable" && (
                <>
                  <br />
                  <select
                    value={selectedObservable}
                    onChange={(e) => setSelectedObservable(e.target.value)}
                    required
                    disabled={!selectedMetricWorkflow}
                  >
                    <option value="">Select Observable</option>
                    {observables.map((observableName) => (
                      <option key={observableName} value={observableName}>
                        {observableName}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {isOsMetric && osMetricType === "action" && (
                <>
                  <br />
                  <select
                    value={selectedAction}
                    onChange={(e) => setSelectedAction(e.target.value)}
                    required
                    disabled={!selectedMetricWorkflow}
                  >
                    <option value="">Select Action</option>
                    {actions.map((actionName) => (
                      <option key={actionName} value={actionName}>
                        {actionName}
                      </option>
                    ))}
                  </select>
                </>
              )}
              <br />
              <select
                value={metricType}
                onChange={(e) => setMetricType(e.target.value)}
                required
                disabled={!selectedMetricWorkflow}
              >
                <option value="">Display Type</option>
                {METRIC_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type.replace("_", " ")}
                  </option>
                ))}
              </select>
              <br />
              <select
                value={metricAggregation}
                onChange={(e) => setMetricAggregation(e.target.value)}
                required
                disabled={!selectedMetricWorkflow}
              >
                <option value="">Aggregation Method</option>
                {METRIC_AGGREGATIONS.map((agg) => (
                  <option key={agg} value={agg}>
                    {agg}
                  </option>
                ))}
              </select>
              <br />
              <select
                value={timeInterval}
                onChange={(e) => setTimeInterval(e.target.value)}
                required
                disabled={!selectedMetricWorkflow}
              >
                <option value="">Time Interval</option>
                {TIME_INTERVALS.map((interval) => (
                  <option key={interval} value={interval}>
                    {interval}
                  </option>
                ))}
              </select>
              <br />
              <select
                value={metricFormat}
                onChange={(e) => setMetricFormat(e.target.value)}
                required
                disabled={!selectedMetricWorkflow}
              >
                <option value="">Format</option>
                {Object.entries(METRIC_FORMATS).map(([format, description]) => (
                  <option key={format} value={format}>
                    {format} {description}
                  </option>
                ))}
              </select>
              <br />
              <button type="submit" disabled={!selectedMetricWorkflow}>
                Add Metric
              </button>
            </form>
            {metricResult && <div className="result">{metricResult}</div>}
          </div>
          <div className="container">
            <h2>Delete Metric</h2>
            <form onSubmit={handleDeleteMetric}>
              <input
                type="text"
                placeholder="Metric ID"
                value={metricIdToDelete}
                onChange={(e) => setMetricIdToDelete(e.target.value)}
                required
              />
              <button type="submit" disabled={!metricIdToDelete}>
                Delete Metric
              </button>
            </form>
            {deleteMetricResult && (
              <div className="result">{deleteMetricResult}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
